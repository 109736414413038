import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions, Control } from "react-redux-form";
import { Pagination } from "antd";
import {
  ButtonComponent,
  isObjectEmpty,
  ModalComponent,
  replaceAttributesInString,
  TabComponent,
} from "../../../../../shared";
import {
  addAircraft,
  CLOSE_MODAL,
  loadAircraftListSuccess,
  loadManufacturerList,
  loadMyAircraftList,
  premiumApply,
  removeAircraft,
  requestVerification,
  RESET_MODEL,
  updateAircraft,
} from "../../../../../actions";
import { ComponentAuthorization } from "../../../../ComponentAuthorization";
import Auth from "../../../../../services/auth";
import { AccountFleetFormComponent } from ".";
import { PDFJS } from "pdfjs-dist";
import {
  BlockComponent,
  ConfirmComponent,
  DisplayContentWithActionComponent,
} from "../../../../common";
import staticService from "../../../../../services/static.service";
import { accountTabs, aocTabs, config, fleetTabs, fleetTabsExtra } from "../../../../../configs";
import { PremiumFormComponent } from "../../../../forms";
import { ButtonWithBadgeComponent, MenuComponent } from "../../common";
import { AccountOnlineLayout } from "../../../../layouts";
import generateLocationAdditionalInfo from "../../../../../utils/generateLocationAdditionalInfo";
import "./fleetList.css";
import FleetHeliComponent from "./fleet-heli.component";
import { clearInvalidDate, transformPickMonth } from "../../../../../utils/dateTimePicker";
import LoadingComponent from "../../../../common/LoadingComponent";

const modalTitle = "Add new aircraft";

const blockClasses = ["uk-section", "uk-padding-remove-top"];

class AccountFleetLayoutComponent extends React.Component {
  constructor(props, context) {
    super(props);
    const items = accountTabs.filter(
      (tab) => tab.permission === "both" || tab.permission === Auth.getRole(),
    );
    this.state = {
      formVisible: false,
      toggleBtn: staticService.findByAlias("addAircraft"),
      modelName: "fleetModel",
      removeDialog: false,
      loadingAircraftList: false,
      loading: false,
      selected: {},
      modalProps: this.modalProps({
        title: modalTitle,
        action: CLOSE_MODAL,
        visible: false,
      }),
      fileReader: new FileReader(),
      files: [],
      pages: [],
      currentFile: null,
      selectedFile: {},
      selectedTab: aocTabs[0],
      tabs: items,
      opened_modal: "",
      to_delete: [],
    };
    this.modalProps = this.modalProps.bind(this);
    this.closeApplyModal = this.closeApplyModal.bind(this);
    this.getFleetForm = this.getFleetForm.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.uploadFileHandler = this.uploadFileHandler.bind(this);
    this.loadFileReader = this.loadFileReader.bind(this);
    this.loadFile = this.loadFile.bind(this);
    this.state.fileReader.onload = this.loadFileReader.bind(this);
    this.getActive = this.getActive.bind(this);
    this.updateOnFileRemove = this.updateOnFileRemove.bind(this);
  }

  componentDidMount() {
    const { loadManufacturerList } = this.props;

    loadManufacturerList();
    this.getMyAircraftList();
  }

  async getMyAircraftList(page) {
    const { loadMyAircraftList } = this.props;

    this.setState({
      loadingAircraftList: true,
    });
    await loadMyAircraftList(page);
    this.setState({
      loadingAircraftList: false,
    });
  }

  get hasActionPermission() {
    return Auth.getRole() === "operator_admin";
  }

  get companyInfo() {
    return Auth.getCompanyFromLocalStorage();
  }

  get info() {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias("aicraftRemoveConfirm"),
        }}
      />
    );
  }

  get modalContent() {
    const { selectedFile } = this.state;
    if (selectedFile && selectedFile.type && selectedFile.type.includes("image")) {
      return (
        <div className="gh-preview-file">
          <img src={selectedFile.file_data} alt="preview" />
        </div>
      );
    } else {
      if (selectedFile.file_data) {
        return (
          <div className="gh-preview-file">
            <iframe src={selectedFile.file_data} title="preview" className="gh-preview-file-pdf" />
          </div>
        );
      }
    }
  }

  get confirmDialog() {
    return (
      <ConfirmComponent
        ref={(confirm) => (this.confirm = confirm)}
        info={this.info}
        buttonsProps={{
          continue: {
            title: staticService.findByAlias("remove"),
            css: "uk-button-danger",
          },
          cancel: { title: staticService.findByAlias("cancel") },
        }}
        modalProps={{
          id: "confirm-modal",
          title: staticService.findByAlias("confirmRemoveAircraftModalTitle"),
          onClose: this.closeModal,
        }}
        onSelect={this.remove.bind(this, this.state.selected)}
        onReject={this.closeModal}
      />
    );
  }

  get modal() {
    return (
      <ModalComponent
        title={this.state.modalProps.title}
        id={"aircraft-modal"}
        options={{ bgClose: false, escClose: false }}
        ref={(modal) => (this.modalRef = modal)}
        onClose={this.closeApplyModal}
      >
        {this.state.modalProps.content}
      </ModalComponent>
    );
  }

  onTabClick(tab, ev) {
    ev.preventDefault();
    this.setState({ selectedTab: tab });
  }

  getActive(loopEl) {
    if (loopEl.alias === this.state.selectedTab.alias) {
      return " uk-active";
    }
    return "";
  }

  loadFileReader(e) {
    PDFJS.getDocument(new Uint8Array(e.target.result)).then((pdf) => {
      // Hardcoded to match the current viewport
      let scale = 1.5;

      let viewport, canvas, context, renderContext;

      // This is a good example of why handling DOM directly w/React is a bad idea
      // Ideally we just use data and grab context from canvas using something like
      // <canvas ref={(c) => this.context = c.getContext('2d')} />
      // otherwise you need to manually keep track of DOM manipulations
      const pageContainer = this._pageContainer;
      let { pages } = this.state;
      pages.map((page) => pageContainer.removeChild(page));
      pages = [];

      for (let i = 1; i <= pdf.numPages; i++) {
        pdf.getPage(i).then((page) => {
          viewport = page.getViewport(scale);

          // Prepare canvas using PDF page dimensions.
          canvas = document.createElement("canvas");
          context = canvas.getContext("2d");

          canvas.height = viewport.height;
          canvas.width = 600;

          // Render PDF page into canvas context.
          renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          page.render(renderContext);
          pageContainer.appendChild(canvas);
          pages.push(canvas);
        });
      }
      this.setState({ pages });
    });
  }

  loadFile(file) {
    // Quick example of short-circuit evaluation
    file !== this.state.currentFile &&
      (this.setState({ currentFile: file }) || this.state.fileReader.readAsDataURL(file));
  }

  uploadFileHandler(file) {
    const { files } = this.state;
    files.push(file);
    this.setState({ files });
    this.loadFile(file);
  }

  closeDialog() {
    return this.confirm.closeModal();
  }

  openPreview(file) {
    this.setState(
      {
        selectedFile: file,
      },
      () => {
        this.setState({
          modalProps: this.modalProps({
            title: file.name,
            content: this.modalContent,
          }),
        });
      },
    );
    this.uploadFileHandler(file);

    return this.modalRef.open();
  }

  getFleetForm(selected = {}) {
    return (
      <AccountFleetFormComponent
        visible={true}
        skipTailNumberValidation={true}
        submitBtn={this.submitBtnTemplate(selected)}
        selectedModel={selected}
        remove={this.openDialog.bind(this, selected)}
        onClose={this.closeApplyModal}
        onUpdateItems={this.onFormSubmit.bind(this)}
        openPreview={this.openPreview.bind(this)}
        updateOnFileRemove={this.updateOnFileRemove}
      />
    );
  }

  remove(aircraft) {
    this.props.remove(aircraft);
  }

  sortDocs(docs) {
    let insuranceList = [];
    let photosList = [];
    let photoAOCList = [];
    if (docs.length) {
      docs.map((doc) => {
        if (doc.document_type === null || doc.document_type === 1) {
          insuranceList.push(doc);
        }
        if (doc.document_type === 2) {
          photosList.push(doc);
        }

        if (doc.document_type === 3) {
          photoAOCList.push(doc);
        }
      });
    }
    this.context.store.dispatch(actions.change("heliInsuranceFilesModel", insuranceList));
    this.context.store.dispatch(actions.change("heliPhotosFilesModel", photosList));
    this.context.store.dispatch(actions.change("heliEvidenceAOCModel", photoAOCList));
  }

  resetFilesList() {
    this.context.store.dispatch(actions.change("filesExistState", false));
    this.context.store.dispatch(actions.change("heliInsuranceFilesModel", []));
    this.context.store.dispatch(actions.change("heliPhotosFilesModel", []));
    this.context.store.dispatch(actions.change("heliEvidenceAOCModel", []));
  }

  edit(model, ev) {
    const { selected } = this.state;

    if (+selected.id === +model.id) {
      this.setState({
        modalProps: this.modalProps({
          title: `Edit ${model.aircraft_model}`,
          content: this.getFleetForm(selected),
        }),
        selected: selected,
      });
      this.context.store.dispatch(actions.merge("fleetModel", selected));
      this.context.store.dispatch(actions.setPristine("fleetModel"));

      this.modalRef.open();
      return;
    }

    ev.preventDefault();
    this.context.store.dispatch(actions.reset("fleetModel"));
    // Generate additional info
    let home_info = model.home_info;
    let home_base = model.home_base;
    let home_base_coords = model.home_base_coords;

    if (model.home_base_location && Object.keys(model.home_base_location).length) {
      const { home_base_location: location } = model;

      home_info = generateLocationAdditionalInfo(location);
      home_base = location.name;

      if (!home_base_coords) {
        home_base_coords = `${location.lat}|${location.lng}`;
      }
    }

    const fleetModel = {
      id: `${model.id}`,
      manufacturer: `${model.manufacturer_id}`,
      type_id: `${model.type_id}`,
      home_base: home_base || "",
      home_info: home_info || "",
      home_base_coords: home_base_coords || "",
      year_manufacture: `${model.year_manufacture}`,
      date_last_refurbished: model.date_last_refurbished,
      registration_number: `${model.registration_number}`,
      max_passengers: `${model.max_passengers}`,
      max_crew: `${model.max_crew}`,
      model: model.aircraft_model,
      files: model.files,
      status: model.status,
      is_helicopter_on_aoc: model.is_helicopter_on_aoc,
      expiration_date: model.expiration_date,
      to_delete: model.to_delete,
      home_base_location: model.home_base_location,
      price_per_hour: model.price_per_hour,
      cruise_speed: model.cruise_speed,
      aircraft_vat: model.aircraft_vat,
    };

    this.setState({
      modalProps: this.modalProps({
        title: `Edit ${model.aircraft_model}`,
        content: this.getFleetForm(fleetModel),
      }),
      selected: fleetModel,
    });

    if (fleetModel.files.length) {
      this.sortDocs(fleetModel.files);
      this.context.store.dispatch(actions.change("filesExistState", true));
    } else {
      this.resetFilesList();
    }

    this.context.store.dispatch(actions.merge("fleetModel", fleetModel));
    this.context.store.dispatch(actions.setPristine("fleetModel"));
    this.modalRef.open();
  }

  openDialog(selected) {
    this.setState({ selected: selected });
    this.confirm.openModal();
  }

  closeApplyModal() {
    const {
      modalProps: {
        content: {
          props: { className },
        },
      },
      opened_modal,
      selectedFile,
    } = this.state;

    const closeFromPreviewModal = !isObjectEmpty(selectedFile);

    this.setState({ opened_modal: "" });
    this.modalRef.hide();

    if (className && className === "gh-preview-file") {
      if (this.state)
        if (opened_modal === "add_aircraft") {
          this.addAircraft();
        } else {
          this.edit(this.state.selected, null);
        }
    }

    if (closeFromPreviewModal) {
      this.setState({ selectedFile: {} });
      return this.edit(this.state.selected, null);
    }

    //reset form on close
    this.setState({ selected: {} });
    this.context.store.dispatch(actions.reset("fleetModel"));
    this.resetFilesList();
  }

  updateOnFileRemove(file) {
    const { document_type, id } = file;
    try {
      const { to_delete } = this.state;

      this.setState(
        {
          to_delete: [
            ...to_delete,
            {
              document_type,
              file_id: id,
            },
          ],
        },
        () =>
          this.context.store.dispatch(
            actions.change(`${this.state.modelName}.to_delete`, this.state.to_delete),
          ),
      );

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  openModal(model) {
    this.setState({
      selected: model,
      modalProps: this.modalProps({
        title: staticService.findByAlias("premiumModalTitle"),
        content: (
          <PremiumFormComponent
            aircraftModel={model}
            onCancel={this.closeApplyModal}
            onSuccess={this.props.premiumApply}
          />
        ),
      }),
    });
    this.modalRef.open();
  }

  onFormSubmit(originalFleet, arg2, onSuccessAction = true) {
    const model = Object.assign({}, originalFleet);
    model.date_last_refurbished = clearInvalidDate(transformPickMonth(model.date_last_refurbished));

    const handleOnSuccess = () => {
      this.context.store.dispatch(actions.reset("fleetModel"));
      this.resetFilesList();
      this.modalRef.hide();
      // this.closeApplyModal();
    };

    if (model.id) {
      this.context.store
        .dispatch(updateAircraft(model, model.id, onSuccessAction ? handleOnSuccess : null))
        .then(() => {
          this.setState({
            selected: {},
          });
        });
    } else {
      this.context.store.dispatch(addAircraft(model, onSuccessAction ? handleOnSuccess : null));
    }
  }

  submitBtnTemplate(model) {
    return (
      <Control.button
        type={"submit"}
        model={"fleetModel"}
        // disabled={true}
        className="uk-button uk-button-primary gh-aircraft-btn"
      >
        {model.id ? staticService.findByAlias("update") : staticService.findByAlias("addAircraft")}
      </Control.button>
    );
  }

  addAircraft() {
    if (!isObjectEmpty(this.state.selected)) {
      this.context.store.dispatch(actions.reset("fleetModel"));
      this.resetFilesList();
    }

    this.modalRef.open();
    this.setState({
      modalProps: this.modalProps({ content: this.getFleetForm() }),
      selected: {},
      opened_modal: "add_aircraft",
    });
  }

  modalProps(info) {
    const data = Object.assign(
      {
        action: CLOSE_MODAL,
        visible: false,
        title: modalTitle,
        content: "",
      },
      info,
    );
    return {
      title: data.title,
      close: this.closeApplyModal,
      content: data.content,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.premiumApplyState) {
      this.context.store.dispatch({ type: RESET_MODEL });
      this.setState({
        modalProps: this.modalProps({
          title: staticService.findByAlias("premiumModalSubmitTitle"),
          content: (
            <DisplayContentWithActionComponent
              action={this.closeApplyModal}
              content={replaceAttributesInString(
                { tail_number: this.state.selected.registration_number },
                staticService.findByAlias("premiumModalSubmitContent"),
              )}
              closeBtn={staticService.findByAlias("close")}
            />
          ),
        }),
      });
    }
  }

  componentWillUnmount() {
    this.context.store.dispatch(actions.reset("fleetModel"));
    this.resetFilesList();
  }

  render() {
    const { myAircraftList, myAircraftListMeta, match, title } = this.props;

    const { has_access_to_extra_helicopters } = this.companyInfo;

    const items = myAircraftList.map((aircraft, index) => {
      return (
        <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-3@l uk-width-1-4@xl" key={index}>
          <FleetHeliComponent
            {...aircraft}
            isLoaderActive={this.state.loading}
            applyCallback={this.openModal.bind(this, aircraft)}
            detailsCallback={this.edit.bind(this)}
          />
        </div>
      );
    });

    return (
      <AccountOnlineLayout activeRoute={match} pageTitle={title}>
        <section className={"team-page"}>
          <BlockComponent
            visible={true}
            parentProps={{
              className: "uk-section uk-padding-remove-bottom header-title",
            }}
          >
            <div className="uk-width-1-1">
              <h3
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("account"),
                }}
              />
            </div>
            <div className={"uk-margin-bottom"}>
              <ButtonWithBadgeComponent
                route={"/marketplace"}
                classes={["btn-pulse"]}
                visible={true}
                label={staticService.findByAlias("operatorMarketPlaceButton")}
              />
            </div>
          </BlockComponent>
          <BlockComponent visible={true} parentProps={{ className: blockClasses.join(" ") }}>
            <TabComponent
              items={this.state.tabs}
              id={"profile-tab"}
              visible={true}
              tabProps={{ className: "uk-tab uk-margin" }}
              onClick={this.onTabClick.bind(this)}
              selected={this.state.selectedTab}
              linkType={"nav"}
            />
            <LoadingComponent
              show={!this.state.loadingAircraftList}
              className="list-aircraft-loader"
            >
              <div data-uk-grid>
                <div className="uk-width-1-1@s uk-width-1-6@m">
                  <div className="uk-margin-small-top">
                    <MenuComponent
                      items={has_access_to_extra_helicopters ? fleetTabsExtra : fleetTabs}
                    />
                  </div>
                </div>
                <div className="uk-width-1-1 uk-width-5-6@m">
                  <div className="uk-margin-bottom uk-text-center uk-text-left@s">
                    <ComponentAuthorization
                      component={ButtonComponent}
                      roles={["operator_admin"]}
                      componentProps={{
                        action: this.addAircraft.bind(this),
                        mapProps: {
                          className: "uk-button uk-button-primary mob-btn",
                        },
                      }}
                    >
                      <span className="uk-text-center uk-margin-small-top">
                        {this.state.toggleBtn}
                      </span>
                    </ComponentAuthorization>
                  </div>
                  {this.confirmDialog}
                  {this.modal}
                  <ul data-uk-grid className="uk-grid-small">
                    {items}
                  </ul>
                  {items.length >= 1 ? (
                    <div className="fleet-pagination-container">
                      <Pagination
                        hideOnSinglePage
                        defaultCurrent={myAircraftListMeta?.current_page}
                        total={myAircraftListMeta?.total_pages * 10}
                        showSizeChanger={false}
                        onChange={(page) => this.getMyAircraftList(page)}
                        className="fleet-pagination"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </LoadingComponent>
          </BlockComponent>
        </section>
      </AccountOnlineLayout>
    );
  }
}

AccountFleetLayoutComponent.contextTypes = {
  store: PropTypes.object,
};

AccountFleetLayoutComponent.propTypes = {
  myAircraftList: PropTypes.array,
  myAircraftListMeta: PropTypes.object,
  match: PropTypes.object,
  title: PropTypes.string,
  premiumApply: PropTypes.func,
  loadMyAircraftList: PropTypes.func,
  loadManufacturerList: PropTypes.func,
};

AccountFleetLayoutComponent.defaultProps = {
  myAircraftList: [],
};

const mapStateToProps = (state) => {
  return {
    myAircraftList: state.aircraft.data,
    myAircraftListMeta: state.aircraft.meta,
    manufacturers: state.manufacturer,
    fleetModel: state.fleetModel,
    premiumApplyState: state.premiumApply,
    errors: state.errorObj,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      save: addAircraft,
      update: updateAircraft,
      remove: removeAircraft,
      load: (list) => dispatch(loadAircraftListSuccess(list)),
      toggleModal: (type, state) => dispatch({ type: type, visible: state }),
      premiumApply,
      requestVerification,
      loadManufacturerList,
      loadMyAircraftList,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(AccountFleetLayoutComponent);
export { COMPONENT as AccountFleetLayoutComponent };
