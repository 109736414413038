import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Control, actions } from "react-redux-form";
import { TableComponent, cloneObj, ModalComponent, storage } from "../../../../shared";
import { FleetFormComponent } from "./fleet-form.component";
import { validateAircraftList, loadManufacturerList } from "../../../../actions/";
import { BaseRegisterFormTemplate } from "../base-form.component";
import { BlockComponent } from "../../../common";
import { List, Item } from "../../../../shared/list";
import staticService from "../../../../services/static.service";
import * as types from "../../../../actions/actionTypes";
import close from "../../../../assets/img/svg/crossInCircle.svg";
import moment from "moment";
import { PDFJS } from "pdfjs-dist";
import { clearInvalidDate } from "../../../../utils/dateTimePicker";

class FleetLayoutComponent extends BaseRegisterFormTemplate {
  constructor(props, context) {
    super(props);
    const localItemsForGrid = storage.get("itemsForGrid");
    const localItemsForServer = storage.get("itemsForServer");
    this.state = {
      itemsForGrid: localItemsForGrid ? JSON.parse(localItemsForGrid) : [],
      itemsForServer: localItemsForServer ? JSON.parse(localItemsForServer) : [],
      headers: [
        staticService.findByAlias("airRegNumber"),
        staticService.findByAlias("YearofManufacture"),
        staticService.findByAlias("manufacturer"),
        staticService.findByAlias("homeBase"),
        staticService.findByAlias("model"),
        staticService.findByAlias("crew"),
        staticService.findByAlias("aircraftPassengers"),
        staticService.findByAlias("aircraftRefurbished"),
      ],
      columns: [
        "registration_number",
        "year_manufacture",
        "manufacturer",
        "home_base",
        "type_id",
        "max_crew",
        "max_passengers",
        "date_last_refurbished",
      ],
      modelName: "fleetModels",
      mainTitle: staticService.findByAlias("wizardFleetTitle"),
      subTitle: staticService.findByAlias("wizardFleetSubTitle"),
      modalVisibility: false,
      selectedFile: {},
      modal: "",
      fileReader: new FileReader(),
      files: [],
      pages: [],
      currentFile: null,
    };

    this.skip = this.skip.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.back = this.back.bind(this);
    this.uploadFileHandler = this.uploadFileHandler.bind(this);
    this.loadFileReader = this.loadFileReader.bind(this);
    this.loadFile = this.loadFile.bind(this);
    this.state.fileReader.onload = this.loadFileReader.bind(this);
    context.store.dispatch(loadManufacturerList());
  }

  skip() {
    super.skip();
    this.setState({ itemsForGrid: [], itemsForServer: [] });
    storage.add("itemsForServer", JSON.stringify(this.state.itemsForServer));
    storage.add("itemsForGrid", JSON.stringify(this.state.itemsForGrid));
  }

  back() {
    storage.add("itemsForServer", JSON.stringify(this.state.itemsForServer));
    storage.add("itemsForGrid", JSON.stringify(this.state.itemsForGrid));
    this.context.store.dispatch({
      type: types.ACCOUNT_REMOTE_VALIDATION,
      user: { user_validation: 0 },
    });
    this.context.store.dispatch({
      type: types.ACCOUNT_INVITATIONS_VALIDATION,
      data: { invitations_validation: 0 },
    });
    return this.props.back();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.validate !== nextProps.validate) {
      this.props.changeView();
    }
  }

  loadFileReader(e) {
    PDFJS.getDocument(new Uint8Array(e.target.result)).then((pdf) => {
      // Hardcoded to match the current viewport
      let scale = 1.5;

      let viewport, canvas, context, renderContext;

      // This is a good example of why handling DOM directly w/React is a bad idea
      // Ideally we just use data and grab context from canvas using something like
      // <canvas ref={(c) => this.context = c.getContext('2d')} />
      // otherwise you need to manually keep track of DOM manipulations
      const pageContainer = this._pageContainer;
      let { pages } = this.state;
      pages.map((page) => pageContainer.removeChild(page));
      pages = [];

      for (let i = 1; i <= pdf.numPages; i++) {
        pdf.getPage(i).then((page) => {
          viewport = page.getViewport(scale);

          // Prepare canvas using PDF page dimensions.
          canvas = document.createElement("canvas");
          context = canvas.getContext("2d");

          canvas.height = viewport.height;
          canvas.width = 600;

          // Render PDF page into canvas context.
          renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          page.render(renderContext);
          pageContainer.appendChild(canvas);
          pages.push(canvas);
        });
      }
      this.setState({ pages });
    });
  }

  loadFile(file) {
    // Quick example of short-circuit evaluation
    file !== this.state.currentFile &&
      (this.setState({ currentFile: file }) || this.state.fileReader.readAsArrayBuffer(file));
  }

  uploadFileHandler(file) {
    const { files } = this.state;
    // const file = e.target.files[0];
    files.push(file);
    this.setState({ files });
    this.loadFile(file);
  }

  remove(index) {
    this.setState((prevState) => {
      const itemsForGrid = prevState.itemsForGrid;
      itemsForGrid.splice(index, 1);
      const itemsForServer = prevState.itemsForServer;
      itemsForServer.splice(index, 1);
      return {
        itemsForGrid: itemsForGrid,
        itemsForServer: itemsForServer,
      };
    });
  }

  toggleModal() {
    this.modal && this.modal.open();
    this.context.store.dispatch(actions.change("heliInsuranceFilesModel", []));
    this.context.store.dispatch(actions.change("heliPhotosFilesModel", []));
    this.context.store.dispatch(actions.change("heliEvidenceAOCModel", []));
  }

  toggleModalPreview(file) {
    this.setState({ selectedFile: file }, () => {
      this.uploadFileHandler(this.state.selectedFile);
      this.previewModal.open();
    });
  }

  closeModal() {
    this.modal.hide();
    this.context.store.dispatch(actions.reset("fleetModel"));
    this.context.store.dispatch(actions.change("filesExistState", false));
    //clear uploaded files
    this.context.store.dispatch(actions.change("heliInsuranceFilesModel", []));
    this.context.store.dispatch(actions.change("heliPhotosFilesModel", []));
    this.context.store.dispatch(actions.change("heliEvidenceAOCModel", []));

    this.setState({ files: [] });
  }

  closeModalPreview() {
    this.previewModal.hide();
    this.modal.open();
    // this.setState({
    //   selectedFile: {},
    //   files: [],
    //   pages: [],
    //   currentFile: null,
    // });
  }

  onUpdateItems(fleet, gridFleet) {
    const itemsForServer = this.state.itemsForServer;
    itemsForServer.push(fleet);
    const mergedModel = Object.assign(cloneObj(fleet), gridFleet);
    const row = this.state.columns.map((column) => {
      return mergedModel[column];
    });
    // if date is Invalid Date, then set it to null
    const interiorRefurbIndex = row.length - 1;
    row[interiorRefurbIndex] = clearInvalidDate(row[interiorRefurbIndex]);

    const itemsForGrid = this.state.itemsForGrid;
    itemsForGrid.push(row);
    this.setState({
      itemsForGrid: itemsForGrid,
      itemsForServer: itemsForServer,
    });
    this.context.store.dispatch(actions.reset("fleetModel"));
    this.context.store.dispatch(actions.reset("heliEvidenceAOCModel"));
    this.context.store.dispatch(actions.change("filesExistState", false));
    this.modal.hide();
  }

  save(ev) {
    ev.preventDefault();
    this.context.store.dispatch(actions.change(this.state.modelName, this.state.itemsForServer));
    this.context.store.dispatch(validateAircraftList(this.state.itemsForServer));
    storage.add("itemsForServer", JSON.stringify(this.state.itemsForServer));
    storage.add("itemsForGrid", JSON.stringify(this.state.itemsForGrid));
  }

  get subTitleDesktop() {
    return (
      <ul data-uk-tab className={"disabled-list gh-uk-tab"}>
        {this.fillExtraSpaces}
        <li className="uk-active uk-width-1-1@s uk-width-1-4@m">
          <a href="#" className="uk-text-left">
            {this.state.subTitle}
          </a>
        </li>
      </ul>
    );
  }

  get formContent() {
    return (
      <FleetFormComponent
        visible={true}
        fleets={this.state.itemsForServer}
        onUpdateItems={this.onUpdateItems.bind(this)}
        continueButton={this.getContinueButtonTemplate()}
        openPreview={this.toggleModalPreview.bind(this)}
        cancel={this.closeModal.bind(this)}
      />
    );
  }

  getRemoveIcon(index) {
    return (
      <td style={{ position: "relative" }}>
        <a onClick={this.remove.bind(this, index)} className="gh-fleet-delete">
          <img className="close" src={close} alt="close" />
        </a>
      </td>
    );
  }

  get modalContent() {
    const { selectedFile } = this.state;
    if (selectedFile && selectedFile.type && selectedFile.type.includes("image")) {
      return (
        <div className="gh-preview-file">
          <img src={selectedFile.file_data} alt="preview" />
        </div>
      );
    } else {
      if (selectedFile.file_data) {
        return (
          <div className="gh-preview-file">
            {/*<div ref={(c) => (this._pageContainer = c)} />*/}
            <iframe src={selectedFile.file_data} title="preview" className="gh-preview-file-pdf" />
          </div>
        );
      }
    }
  }
  getSkipButtonTemplate() {
    return (
      <Control.button
        type={"button"}
        model={this.state.modelName}
        disabled={this.state.itemsForGrid.length}
        className="uk-button uk-button-text uk-align-center"
        onClick={this.skip}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("skipStep"),
          }}
        />
      </Control.button>
    );
  }
  get content() {
    return (
      <BlockComponent
        visible={true}
        containerClass="uk-container gh-container-affiliate"
        parentProps={{
          className: "uk-section uk-margin-medium-top uk-padding-remove gh-section-statistics",
        }}
      >
        {/*<div className="uk-text-center uk-position-relative uk-margin-small-top uk-margin-large-bottom uk-hidden@m">*/}
        {/*  <div className={"gh-middle-line"} />*/}
        {/*  <button*/}
        {/*    type={"button"}*/}
        {/*    className={"reset-btn pointer uk-position-relative"}*/}
        {/*    onClick={this.toggleModal.bind(this, true)}*/}
        {/*  >*/}
        {/*    <span*/}
        {/*      className="uk-icon-button uk-button-primary"*/}
        {/*      data-uk-icon="icon: plus"*/}
        {/*    />*/}
        {/*  </button>*/}
        {/*</div>*/}
        <TableComponent
          rows={this.state.itemsForGrid.map((row) => {
            const updatedRow = [...row];

            let date = updatedRow.pop();

            if (moment(date).isValid()) {
              date = moment(date).format("MMMM, YYYY");
            }

            return [...updatedRow, date];
          })}
          tableProps={{ className: "uk-table uk-visible@m" }}
          headers={this.state.headers}
          actionElements={this.getRemoveIcon.bind(this)}
          showActionColumn={true}
          visible={this.state.itemsForGrid.length > 0}
        />
        <div className={"uk-hidden@m"}>
          <List attrProps={{ className: "disabled-list" }}>
            {this.state.itemsForGrid.map((row, key) => {
              return (
                <Item key={key}>
                  {this.state.headers.map((header, i) => {
                    return (
                      <div data-uk-grid key={i} className={"uk-margin-remove-top"}>
                        <div className={"uk-width-2-3"}>
                          <b className={"font-weight-500"}>{header}</b>:{" "}
                          {row[i] !== null ? row[i] : "-"}
                        </div>
                        <div className="gh-fleet-delete">
                          {i === 0 ? (
                            <span
                              className="uk-link-text pointer"
                              onClick={this.remove.bind(this, key)}
                            >
                              <img className="close" src={close} alt="close" />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </Item>
              );
            })}
          </List>
        </div>
        <div className="gh-fleet-container">
          <a className="gh-fleet-btn" onClick={this.toggleModal.bind(this, true)}>
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("addAircraftLabel"),
              }}
            />
          </a>
        </div>
        <ModalComponent
          title={staticService.findByAlias("fleetModalTitle")}
          options={{ clsPage: "", bgClose: false, escClose: false }}
          id="fleet-modal"
          ref={(modal) => (this.modal = modal)}
          onClose={this.closeModal.bind(this)}
        >
          {this.formContent}
        </ModalComponent>
        <ModalComponent
          title={this.state.selectedFile.file_name || this.state.selectedFile.name || ""}
          options={{ clsPage: "", bgClose: false, escClose: false }}
          id="preview-modal"
          ref={(previewModal) => (this.previewModal = previewModal)}
          onClose={this.closeModalPreview.bind(this)}
        >
          {this.modalContent}
        </ModalComponent>
        <hr className="gh-divider" />

        <div className="uk-flex uk-flex-between gh-register-btns">
          <div>{this.getBackButtonTemplate()}</div>
          <div className="uk-visible@s">{this.getSkipButtonTemplate()}</div>
          <div>{this.getContinueButtonTemplate()}</div>
        </div>

        <div className="uk-hidden@s uk-flex uk-flex-center gh-register-btns">
          {this.getSkipButtonTemplate()}
        </div>
        <p className="uk-text-center">
          <small
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("fleetMessageUnderSkip"),
            }}
          />
        </p>
      </BlockComponent>
    );
  }

  getContinueButtonTemplate(options = {}) {
    return (
      <Control.button
        type={"button"}
        model={this.state.modelName}
        disabled={this.state.itemsForServer.length === 0}
        onClick={this.save.bind(this)}
        className={"uk-button uk-button-primary"}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("continue"),
          }}
        />
      </Control.button>
    );
  }
}

FleetLayoutComponent.propTypes = {
  changeView: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

FleetLayoutComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    fleet: state.fleetModel,
    redirectAction: !state.manufacturer.length,
    fleetModels: state.fleetModels,
    validate: state.validateAircrafts.aircraft_validation,
  };
};

const COMPONENT = connect(mapStateToProps)(FleetLayoutComponent);
export { COMPONENT as FleetLayoutComponent };
