import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { config, pages } from "../../../configs";
import Auth from "../../../services/auth";
import apiHandler from "../../../services/api-handler";
import { spinnerInstance } from "../../../shared";
import staticService from "../../../services/static.service";
import { EventsLayout } from "../../layouts";
import EventCard from "../../common/EventCard";
import Button from "../../common/Button";

const TagsList = (props) => {
  const { match, title } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsPagination, setTagsPagination] = useState({});

  const filteredPages = () => {
    return pages.filter((page) => {
      return !(page.title && page.title.indexOf("bookADemo") !== -1 && Auth.isAuthenticated());
    });
  };

  const loadTags =
    (page = 1) =>
    () => {
      const tagId = match.params.id;
      setIsLoading(true);
      const tagIdWithUnderscore = tagId.replace(/-/g, "_");

      apiHandler
        .setProperty("skipErrorAlert", true)
        .setQueryParams()
        .setPath(`helicopterflights/${tagIdWithUnderscore}`, `?page=${page}`)
        .getAll()
        .then((res) => {
          if (page > 1) {
            setTags([...tags, ...res.data]);
          } else {
            setTags(res.data);
          }
          setTagsPagination(res.meta.pagination);
          setIsLoading(false);
          return res;
        })
        .catch((err) => {
          setIsLoading(false);
          spinnerInstance.setProp("type", "large").hide();
          return err;
        });
    };

  useEffect(() => {
    loadTags()();
  }, []);

  return (
    <EventsLayout
      activeRoute={match}
      pageTitle={title}
      pages={filteredPages()}
      registerPath={config.redirectToRegister}
    >
      <div className="info-block">
        {/*{eventsBg}*/}

        <div className="uk-section uk-container gh-padding-medium">
          <div className="uk-grid-match uk-flex-middle uk-child-width-1-1@s uk-grid">
            <div className="uk-first-column gh-info-block-description-box uk-width-1-1@s">
              <h1 className="uk-margin-remove-top uk-scrollspy-inview uk-animation-slide-left-medium gh-event-title">
                {staticService.findByAlias("tagsListTitle")}
              </h1>
              <span className="uk-margin-medium uk-text-emphasis uk-margin-remove-top uk-scrollspy-inview uk-animation-slide-left-medium">
                <span
                  className="gh-subtitle-text"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("tagsListSubtitle"),
                  }}
                />
              </span>
            </div>
          </div>
        </div>

        <div className="uk-section uk-container gh-padding-medium uk-padding-remove-top">
          {tags.map((tag) => (
            <EventCard event={tag} key={tag.url} />
          ))}
        </div>

        {tagsPagination && tagsPagination.total_pages > tagsPagination.current_page ? (
          <div style={{ padding: "15px 0 35px", display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              color="primaryBorder"
              style={{ border: "2px solid #dfe2e8", fontWeight: 400 }}
              onClick={loadTags(tagsPagination.current_page + 1)}
              loading={isLoading}
            >
              {staticService.findByAlias("showMore")}
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </EventsLayout>
  );
};

TagsList.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string,
};

export default TagsList;
