import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions, Control } from "react-redux-form";
import { ModalComponent } from "../../../shared";
import { BaseRegisterFormTemplate } from "./base-form.component";
import staticService from "../../../services/static.service";
import UploadFiles from "./upload-files.component";
import { PDFJS } from "pdfjs-dist";
import * as types from "../../../actions/actionTypes";

class UploadFormComponent extends BaseRegisterFormTemplate {
  constructor(props) {
    super(props);

    this.state = {
      modelName: "companyFilesModel",
      showError: false,
      mainTitle: staticService.findByAlias("uploadAccountVerification"),
      subTitle: staticService.findByAlias("uploadDocuments"),
      errorMessage: staticService.findByAlias("noFileUploadMessage"),
      serverErrors: [],
      selectedFile: {},
      fileReader: new FileReader(),
      files: [],
      pages: [],
      currentFile: null,
    };
    this.skip = this.skip.bind(this);
    this.back = this.back.bind(this);
    this.uploadFileHandler = this.uploadFileHandler.bind(this);
    this.loadFileReader = this.loadFileReader.bind(this);
    this.loadFile = this.loadFile.bind(this);
    this.state.fileReader.onload = this.loadFileReader.bind(this);
  }

  componentDidMount() {
    this.context.store.dispatch(actions.change("filesExistState", true));
  }

  skip() {
    this.setState({ files: [] });
    this.props.changeView();
  }

  skipStep() {
    this.context.store.dispatch(actions.change(this.state.modelName, []));
    super.skip();
  }

  back() {
    this.context.store.dispatch({
      type: types.ACCOUNT_FLEET_VALIDATION,
      data: { aircraft_validation: 0 },
    });
    this.context.store.dispatch({
      type: types.ACCOUNT_INVITATIONS_VALIDATION,
      data: { invitations_validation: 0 },
    });
    return this.props.back();
  }

  get subTitleDesktop() {
    return (
      <ul data-uk-tab className={"disabled-list gh-uk-tab"}>
        {this.fillExtraSpaces}
        <li className="uk-active uk-width-1-4@s uk-width-1-4@m">
          <a href="#" className="uk-text-left">
            {this.state.subTitle}
          </a>
        </li>
      </ul>
    );
  }

  get subTitle() {
    return (
      <div>
        <div className={"uk-hidden@m"}>{this.subTitleMobile}</div>
        <div className={"uk-visible@m"}>{this.subTitleDesktop}</div>
      </div>
    );
  }

  getSkipButtonTemplate() {
    return (
      <Control.button
        type={"button"}
        disabled={this.props.aocFilesModel.length}
        model={this.state.modelName}
        className="uk-button uk-button-text uk-align-center"
        onClick={this.skipStep.bind(this)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("skipStep"),
          }}
        />
      </Control.button>
    );
  }

  getContinueButtonTemplate() {
    return (
      <Control.button
        type={"button"}
        model={this.state.modelName}
        disabled={!this.props.aocFilesModel?.length}
        onClick={this.submitFiles.bind(this)}
        className={"uk-button uk-button-primary uk-align-center uk-align-right@m"}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("continue"),
          }}
        />
      </Control.button>
    );
  }

  submitFiles() {
    const { aocFilesModel } = this.props;
    // const arr = [];
    // const files = arr.concat(certificateFilesModel, aocFilesModel);
    this.context.store.dispatch(actions.change("userRegisterModel.files", aocFilesModel));
    this.props.changeView();
  }

  openModal(file) {
    this.setState({ selectedFile: file }, () => {
      this.uploadFileHandler(this.state.selectedFile);
      this.modal.open();
    });
  }

  closeModal() {
    this.modal.hide();
    this.setState({
      selectedFile: {},
      files: [],
      pages: [],
      currentFile: null,
    });
  }
  loadFileReader(e) {
    PDFJS.getDocument(new Uint8Array(e.target.result)).then((pdf) => {
      // Hardcoded to match the current viewport
      let scale = 1.5;

      let viewport, canvas, context, renderContext;

      // This is a good example of why handling DOM directly w/React is a bad idea
      // Ideally we just use data and grab context from canvas using something like
      // <canvas ref={(c) => this.context = c.getContext('2d')} />
      // otherwise you need to manually keep track of DOM manipulations
      const pageContainer = this._pageContainer;
      let { pages } = this.state;
      pages.map((page) => pageContainer.removeChild(page));
      pages = [];

      for (let i = 1; i <= pdf.numPages; i++) {
        pdf.getPage(i).then((page) => {
          viewport = page.getViewport(scale);

          // Prepare canvas using PDF page dimensions.
          canvas = document.createElement("canvas");
          context = canvas.getContext("2d");

          canvas.height = viewport.height;
          canvas.width = 600;

          // Render PDF page into canvas context.
          renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          page.render(renderContext);
          pageContainer.appendChild(canvas);
          pages.push(canvas);
        });
      }
      this.setState({ pages });
    });
  }

  loadFile(file) {
    // Quick example of short-circuit evaluation
    file !== this.state.currentFile &&
      (this.setState({ currentFile: file }) || this.state.fileReader.readAsArrayBuffer(file));
  }

  uploadFileHandler(file) {
    const { files } = this.state;
    // const file = e.target.files[0];
    files.push(file);
    this.setState({ files });
    this.loadFile(file);
  }

  get modalContent() {
    const { selectedFile } = this.state;
    if (selectedFile && selectedFile.type && selectedFile.type.includes("image")) {
      return (
        <div className="gh-preview-file">
          <img src={selectedFile.file_data} alt="preview" />
        </div>
      );
    } else {
      if (selectedFile.file_data) {
        return (
          <div className="gh-preview-file">
            {/*<div ref={(c) => (this._pageContainer = c)} />*/}
            <iframe src={selectedFile.file_data} title="preview" className="gh-preview-file-pdf" />
          </div>
        );
      }
    }
  }

  previewFile() {
    return (
      <ModalComponent
        title={this.state.selectedFile.file_name ? this.state.selectedFile.file_name : ""}
        ref={(modal) => (this.modal = modal)}
        id="preview-file"
        modalClass="gh-preview-modal"
        options={{ clsPage: "", bgClose: false, escClose: false }}
        onClose={this.closeModal.bind(this)}
      >
        {this.modalContent}
      </ModalComponent>
    );
  }

  get formContent() {
    return (
      <div>
        <div
          className="gh-provide-title"
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("provideCopies"),
          }}
        />
        {this.previewFile()}
        {/*<UploadFiles*/}
        {/*  model={this.props.certificateFilesModel}*/}
        {/*  modelName="certificateFilesModel"*/}
        {/*  uploadTitle="evidenceOfCertificate"*/}
        {/*  docType={1}*/}
        {/*  openModal={this.openModal.bind(this)}*/}
        {/*/>*/}
        <UploadFiles
          model={this.props.aocFilesModel}
          modelName="aocFilesModel"
          uploadTitle="evidenceOfAoc"
          docType={2}
          openModal={this.openModal.bind(this)}
        />
        {/*<UploadFiles*/}
        {/*  model={this.props.evidenceFilesModel}*/}
        {/*  modelName="evidenceFilesModel"*/}
        {/*  uploadTitle="evidenceOfAssociation"*/}
        {/*  docType={3}*/}
        {/*  openModal={this.openModal.bind(this)}*/}
        {/*/>*/}
        <hr className="gh-divider" />
        <div className="uk-flex uk-flex-between gh-register-btns">
          <div>{this.getBackButtonTemplate()}</div>
          <div className="uk-visible@s">{this.getSkipButtonTemplate()}</div>
          <div>{this.getContinueButtonTemplate()}</div>
        </div>
        <div className="uk-hidden@s uk-flex uk-flex-center gh-register-btns">
          {this.getSkipButtonTemplate()}
        </div>
        <p className="uk-text-center">
          <small
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("documentsMessageUnderSkip"),
            }}
          />
        </p>
      </div>
    );
  }

  render() {
    if (this.props.visible) {
      return super.render();
    }
    return null;
  }
}

UploadFormComponent.propTypes = {
  changeView: PropTypes.func,
  visible: PropTypes.bool,
};

UploadFormComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    ...state.userRegisterModel,
    user: state.userRegisterModel.type,
    errors: state.errorObj,
    companyFilesModel: state.companyFilesModel,
    // certificateFilesModel: state.certificateFilesModel,
    aocFilesModel: state.aocFilesModel,
    // evidenceFilesModel: state.evidenceFilesModel,
    validate: state.validateDocuments.file_validation,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(UploadFormComponent);
export { COMPONENT as UploadFormComponent };
