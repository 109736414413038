import React from "react";
import { AccountOnlineLayout } from "../../../layouts";
import { IS_PREV_PAGE_BOOKING_DETAILS, pages } from "../../../../configs";
import staticService from "../../../../services/static.service";
import UploadFiles from "../../../forms/register/upload-files.component";
import { connect } from "react-redux";
import { PDFJS } from "pdfjs-dist";
import { downloadDocImage, getAocDocs } from "../../../../actions";
import { ModalComponent, redirectTo, storage } from "../../../../shared";
import download from "../../../../assets/img/download.png";
import auth from "../../../../services/auth";

class AocDocumentsRoom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: {},
      fileReader: new FileReader(),
      files: [],
      currentFile: null,
      typeOfDocuments: null,
    };
    this.filteredPages = this.filteredPages.bind(this);
    this.uploadFileHandler = this.uploadFileHandler.bind(this);
    this.loadFileReader = this.loadFileReader.bind(this);
    this.loadFile = this.loadFile.bind(this);
    this.state.fileReader.onload = this.loadFileReader.bind(this);
    this.isPrevPageBookingDetails = storage.get(IS_PREV_PAGE_BOOKING_DETAILS);
  }

  componentDidMount() {
    const { type, id } = this.props.computedMatch.params;
    this.setState({ typeOfDocuments: type });
    this.props.getAocDocs(id, type ? type : "booking");
    /*===========Clear Local Storage before closing the window===========*/
    window.onbeforeunload = () => storage.delete(IS_PREV_PAGE_BOOKING_DETAILS);
  }

  filteredPages() {
    return pages.filter((page) => {
      return !(page.hideOnPages && page.hideOnPages.indexOf("contact") !== -1);
    });
  }

  openModal(file) {
    this.setState({ selectedFile: file }, () => {
      this.uploadFileHandler(file);
      this.modal.open();
    });
  }

  closeModal() {
    this.modal.hide();
    setTimeout(() => {
      this.setState({
        selectedFile: {},
        files: [],
        pages: [],
        currentFile: null,
      });
    }, 500);
  }

  loadFileReader(e) {
    PDFJS.getDocument(new Uint8Array(e.target.result)).then((pdf) => {
      // Hardcoded to match the current viewport
      let scale = 1.5;

      let viewport, canvas, context, renderContext;

      // This is a good example of why handling DOM directly w/React is a bad idea
      // Ideally we just use data and grab context from canvas using something like
      // <canvas ref={(c) => this.context = c.getContext('2d')} />
      // otherwise you need to manually keep track of DOM manipulations
      const pageContainer = this._pageContainer;
      let { pages } = this.state;
      pages.map((page) => pageContainer.removeChild(page));
      pages = [];

      for (let i = 1; i <= pdf.numPages; i++) {
        pdf.getPage(i).then((page) => {
          viewport = page.getViewport(scale);

          // Prepare canvas using PDF page dimensions.
          canvas = document.createElement("canvas");
          context = canvas.getContext("2d");

          canvas.height = viewport.height;
          canvas.width = 600;

          // Render PDF page into canvas context.
          renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          page.render(renderContext);
          pageContainer.appendChild(canvas);
          pages.push(canvas);
        });
      }
      this.setState({ pages });
    });
  }

  loadFile(file) {
    // Quick example of short-circuit evaluation
    file !== this.state.currentFile &&
      (this.setState({ currentFile: file }) || this.state.fileReader.readAsArrayBuffer(file));
  }

  uploadFileHandler(file) {
    const { files } = this.state;
    const blob = new Blob([file]);
    // const file = e.target.files[0];
    files.push(file);
    this.setState({ files });
    this.loadFile(blob);
  }

  downloadImg() {
    const url =
      this.state.selectedFile.file_data.split("/storage")[1] ||
      this.state.selectedFile.file_data.slice(40);
    return this.props.downloadDocImage(this.state.selectedFile, url);
  }

  get modalContent() {
    const { selectedFile } = this.state;
    if (selectedFile && selectedFile.type && selectedFile.type.includes("application/pdf")) {
      return (
        <div className="gh-preview-file">
          {/*<div ref={(c) => (this._pageContainer = c)} />*/}
          <iframe src={selectedFile.file_data} title="preview" className="gh-preview-file-pdf" />
        </div>
      );
    } else {
      return (
        <div className="gh-preview-file gh-image-box">
          <img src={selectedFile.file_data} alt="preview" />
          <a
            className="uk-button uk-button-secondary uk-padding-remove gh-image-box-btn"
            onClick={this.downloadImg.bind(this)}
          >
            <img className="gh-image-box-icon" src={download} alt="download" />
            {staticService.findByAlias("download")}
          </a>
        </div>
      );
    }
  }

  previewFile() {
    return (
      <ModalComponent
        title={this.state.selectedFile.file_name || this.state.selectedFile.name || ""}
        ref={(modal) => (this.modal = modal)}
        id="preview-file-account"
        modalClass="gh-preview-modal"
        options={{ clsPage: "", bgClose: false, escClose: false }}
        onClose={this.closeModal.bind(this)}
      >
        {this.modalContent}
      </ModalComponent>
    );
  }

  render() {
    if (!auth.hasFeature("downloadable_documents") && !this.isPrevPageBookingDetails)
      return redirectTo("/");
    return (
      <AccountOnlineLayout activeRoute={this.props.match} pages={this.filteredPages()}>
        <section className="gh-aoc-docs">
          <div className="uk-margin-medium-top">
            {this.previewFile()}
            <div className="uk-container">
              <h3
                dangerouslySetInnerHTML={{
                  __html: this.state.typeOfDocuments
                    ? staticService.findByAlias("documentsTitle")
                    : staticService.findByAlias("aocDocumentsTitle"),
                }}
              />
              <hr className="gh-page-line" />
              <UploadFiles
                model={this.props.aocDocs}
                modelName="aocDocumentsModel"
                openModal={this.openModal.bind(this)}
                hiddenUpload={true}
                docs={this.props.aocDocs}
                hideRemove={true}
              />
            </div>
          </div>
        </section>
      </AccountOnlineLayout>
    );
  }
}

const COMPONENT = connect(({ aocDocs }) => ({ aocDocs }), {
  getAocDocs,
  downloadDocImage,
})(AocDocumentsRoom);

export { COMPONENT as AocDocumentsRoom };
